import React from "react"
import Navbar from "../components/Navbar"
import styles from "./page.module.css"

const Home = () => {

  CountDownTimer('06/01/2024 12:01 AM', 'countdown');
  
      function CountDownTimer(dt, id)
      {
          var end = new Date(dt);
  
          var _second = 1000;
          var _minute = _second * 60;
          var _hour = _minute * 60;
          var _day = _hour * 24;
          var timer;
  
          function showRemaining() {
              var now = new Date();
              var distance = end - now;
              if (distance < 0) {
  
                  clearInterval(timer);
                  document.getElementById(id).innerHTML = 'EXPIRED!';
  
                  return;
              }
              var days = Math.floor(distance / _day);
              var hours = Math.floor((distance % _day) / _hour);
              var minutes = Math.floor((distance % _hour) / _minute);
              var seconds = Math.floor((distance % _minute) / _second);
  
              document.getElementById(id).innerHTML = days + ' : ';
              document.getElementById(id).innerHTML += hours + ' : ';
              document.getElementById(id).innerHTML += minutes + ' : ';
              document.getElementById(id).innerHTML += seconds + ' ';
          }
  
          timer = setInterval(showRemaining, 1000);
      }
 
  return (
    <div className={styles.bodyn}>
      <Navbar />
      <div className={styles.ninja}>
        <h1 className={styles.diff}>Hawaii Tech</h1>
        <h2 className={styles.h2n}>Business Coming Soon</h2>
        <div className={styles.h3n} id="countdown">
          
        </div>
      </div>
    </div>
  )
}
export default Home