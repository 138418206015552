import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import { MdClose } from "react-icons/md"
import { FiMenu } from "react-icons/fi"


const Navbar = () => {
  const links = [
    {
      id: 1,
      path: "/",
      text: "Home",
    },
    {
      id: 2,
      path: "/about",
      text: "About",
    },
    {
      id: 3,
      path: "/todo",
      text: "Todo App",
    },
  ]
  const [navbarOpen, setNavbarOpen] = useState(false)
  const handleToggle = () => {
    setNavbarOpen(prev => !prev)
  }
  return (
    <nav className="navBar">
      <button onClick={handleToggle}>
      {navbarOpen ? (
        <MdClose style={{ color: "#fff", width: "20px", height: "20px" }} />
      ) : (
        <FiMenu style={{ color: "#7b7b7b", width: "20px", height: "20px" }} />
      )}
      </button>
      <ul className={`menuNav ${navbarOpen ? "showMenu" : ""}`}>
        {links.map(link => {
          return (
            <li key={link.id}>
              <NavLink to={link.path} activeclassname="active-link" exact="true">
                {link.text}
              </NavLink>
            </li>
          )
        })}
      </ul>
    </nav>
  )  
}
export default Navbar