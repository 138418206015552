import React from "react"
import ReactDOM from "react-dom"
//component files
import TodoContainer from "./functionBased/components/TodoContainer"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import About from "./functionBased/pages/About"
import NotMatch from "./functionBased/pages/NotMatch"
import Home from "./functionBased/pages/Home"

//stylesheet
import "./functionBased/App.css"

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/todo' element={<TodoContainer />}></Route>
          <Route path='/about' element={<About />}></Route>
          <Route path='*' element={<NotMatch />}></Route>            
        </Routes> 
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
)

//OLD
// const element = <h1>Hellow from Create React App</h1>
// ReactDOM.render(element, document.getElementById("root"))