import React from "react"
import Navbar from "../components/Navbar"
import styles from "./page.module.css"

const About = () => {

  return (
    <div className={styles.bodyn}>
      <Navbar />
      <div className={styles.wrappered}>
        <div className={styles.blurb}>
          <div className={styles.pic}></div>
          <h1>Casey Mayes</h1>
          <p>Born and raised in the small mountain community of 
            Estes Park, Colorado. Lived, worked and enjoyed being
            part of the Kona community, here in Hawaii for over a 
            decade. The similarities between Estes Park and Kona 
            are striking. Both have tourist driven economies, both
            nurture, guide and rely on local businesses. 
          </p><p> 
            I have been working with small businesses most of my life.
            20 years of experience in information technology. 
            10 of those years working with start ups, consulting and
            establishing mission critical IT infrastructure for whose 
            organizational goals have been to become publicly 
            traded entities.
          </p><p>    
            I am driven to extend my knowledge and expertise in 
            providing local businesses the same enterprise level 
            service, which are usually only available to large
            corporations, at an affordable cost. 
          </p><p>  
            For companies that do not have the resources to hire a
            full time IT person or staff - Partner with Hawaii Tech 
          </p>
        </div>
      </div>
    </div>
  )
}
export default About
